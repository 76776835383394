<template>
  <div class="headerPic-container">
    <div class="cus-tabs">
      <div class="cus-tabs-item" @click="activeTabs = 'upload';selectHeaderImgId=''" :class="activeTabs === 'upload' ? 'cus-tabs-item-active': ''">
        上传图片
      </div>
      <div class="cus-tabs-item" @click="activeTabs = 'recommend'" :class="activeTabs === 'recommend' ? 'cus-tabs-item-active': ''">
        系统推荐
      </div>
    </div>
    <div v-if="activeTabs === 'upload'" style="text-align: left;padding: 20px 0;height: 200px;display: flex;align-items: baseline">
      <img class="headerImg" style="vertical-align: bottom;width: 128px;height: 128px;margin-right: 50px" :src="headerImgUrl" alt="">
      <el-upload
              class="avatar-uploader"
              action="/tzapi/upload/putFile"
              :show-file-list="false"
              name="multipartFile"
              accept="image/png, image/jpeg"
              :on-success="handleAvatarSuccess">
        <!--<img v-if="imageUrl" :src="imageUrl" class="avatar">-->
        <el-button size="small">修改头像</el-button>
        <!--<i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
      </el-upload>
    </div>
    <div v-if="activeTabs === 'recommend'" class="headerPic-box">
      <div :class="selectHeaderImgId === 'one'? 'active-selectRecomImg': 'selectRecomImg-box'" @click="selectHeaderImg('one')">
        <img src="https://yanxiukecheng-1304308235.cos.ap-nanjing.myqcloud.com/47a712a6-fa57-4c88-81d3-da251abb0bcf.jpeg" alt="">
      </div>
      <div :class="selectHeaderImgId === 'two'? 'active-selectRecomImg': 'selectRecomImg-box'" @click="selectHeaderImg('two')">
        <img src="https://yanxiukecheng-1304308235.cos.ap-nanjing.myqcloud.com/f8287e74-0bb8-48cf-b1d1-0a3a40c365ed.jpeg" alt="">
      </div>
      <div :class="selectHeaderImgId === 'three'? 'active-selectRecomImg': 'selectRecomImg-box'" @click="selectHeaderImg('three')">
        <img src="https://yanxiukecheng-1304308235.cos.ap-nanjing.myqcloud.com/f834a4de-221b-40f5-9ab0-b5879e155df0.jpeg" alt="">
      </div>
      <div :class="selectHeaderImgId === 'four'? 'active-selectRecomImg': 'selectRecomImg-box'" @click="selectHeaderImg('four')">
        <img src="https://yanxiukecheng-1304308235.cos.ap-nanjing.myqcloud.com/a84ee57c-87a8-4a97-b437-c9b98b66cad8.jpeg" alt="">
      </div>
    </div>
    <el-button type="primary" @click="createSpecial">保存</el-button>
  </div>
</template>

<script>
  import cookieUtil from "@/util/cookieUtil"
  export default {
    name: "headerPic",
    data() {
      return {
        activeTabs: 'upload',
        userInfo: {
          headPortrait: ''
        },
        headerImgUrl: 'https://yanxiukecheng-1304308235.cos.ap-nanjing.myqcloud.com/13aec235-d4d1-4dc2-b0f3-c84d594d538d.png',
        selectHeaderImgId: ''
      }
    },
    mounted() {
      Object.assign(this.userInfo, JSON.parse(cookieUtil.getCookie('userInfo')))
      this.headerImgUrl = this.userInfo.headPortrait
    },
    methods: {
      handleAvatarSuccess(res, file) {
        this.headerImgUrl = res.result
        this.$emit('onSuccess', res.result)
      },
      saveHeaderImg() {},
      selectHeaderImg(selectHeaderImgId) {
        this.selectHeaderImgId = selectHeaderImgId
      },
      createSpecial() {
        let headPortrait = ''
        if (this.activeTabs === 'recommend') {
          switch (this.selectHeaderImgId) {
            case 'one':
              headPortrait = 'https://yanxiukecheng-1304308235.cos.ap-nanjing.myqcloud.com/47a712a6-fa57-4c88-81d3-da251abb0bcf.jpeg';
              break;
            case 'two':
              headPortrait = 'https://yanxiukecheng-1304308235.cos.ap-nanjing.myqcloud.com/f8287e74-0bb8-48cf-b1d1-0a3a40c365ed.jpeg';
              break;
            case 'three':
              headPortrait = 'https://yanxiukecheng-1304308235.cos.ap-nanjing.myqcloud.com/f834a4de-221b-40f5-9ab0-b5879e155df0.jpeg';
              break;
            case 'four':
              headPortrait = 'https://yanxiukecheng-1304308235.cos.ap-nanjing.myqcloud.com/a84ee57c-87a8-4a97-b437-c9b98b66cad8.jpeg';
              break;
          }
        }
        if (this.activeTabs === 'upload') {
          headPortrait = this.headerImgUrl
        }
        this.$api.home.createSpecial({
          ...this.userInfo,
          headPortrait
        }).then(res => {
          if (res.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.headerPic-container {
  padding: 20px;
  .headerPic-box {
    /*height: 200px;*/
    display: flex;
    /*justify-content: space-between;*/
    padding: 20px 0;
    img {
      width: 128px;
      height: 128px;
      vertical-align: middle;
    }
  }
  .cus-tabs {
    display: flex;
    font-size: 15px;
    .cus-tabs-item {
      width: 95px;
      height: 36px;
      line-height: 36px;
      background-color: #f7f7f7;
      cursor: pointer;
    }
    .cus-tabs-item-active {
      color: #fff;
      background-color: #409eff;
    }
  }
  .active-selectRecomImg {
    padding: 10px;
    border: 2px solid #409eff;
    text-align: center;
  }
  .selectRecomImg-box {
    padding: 10px;
    border: 2px solid #fff;
  }
}
</style>