<template>
  <div class="userInfo-container">
    <div class="userInfo1">
      <div class="img-box">
        <img :src="userInfo.headPortrait" alt="">
      </div>
      <p>{{ userInfo.userName }}</p>
      <p>{{ userInfo.schoolName }}</p>
      <div class="sidebar-nav">
        <div :class="sidebarId === 'info' ? 'sidebar-nav-item-active': ''"
             @click="sidebarId = 'info'" >
          <img src="../../assets/header_icon.png" alt="">个人资料
        </div>
        <div :class="sidebarId === 'headerPic' ? 'sidebar-nav-item-active': ''"
             @click="sidebarId = 'headerPic'">
          <img src="../../assets/pic_icon.png" alt="">修改头像
        </div>
        <div :class="sidebarId === 'netMessage' ? 'sidebar-nav-item-active': ''"
             @click="siderBarClick('netMessage')">
          <img src="../../assets/message_icon.png" alt="">网络发信
        </div>
        <div :class="sidebarId === 'loginOut' ? 'sidebar-nav-item-active': ''"
             @click="loginOut">
          <img src="../../assets/logoout_icon.png" alt="">退出登录
        </div>
      </div>
    </div>
    <div class="userInfo2">
      <info v-if="sidebarId === 'info'" :userInfo="userInfo"></info>
      <headerPic v-if="sidebarId === 'headerPic'"></headerPic>
      <loginOut v-if="sidebarId === 'loginOut'"></loginOut>
    </div>
    <div class="userInfo3">
      <p>登录名：{{ userInfo.userName }}</p>
      <p>真实姓名：{{ userInfo.trueName }}</p>
      <p>单位：{{ userInfo.schoolName }}</p>
    </div>
  </div>
</template>

<script>
  import info from './info'
  import headerPic from './headerPic'
  import loginOut from './loginOut'
  import cookieUtil from "@/util/cookieUtil"
  export default {
    name: "userInfo",
    props: {
      userInfo: {}
    },
    components: {
      info, headerPic, loginOut
    },
    data() {
      return {
        sidebarId: 'info'
      }
    },
    methods: {
      loginOut() {
        cookieUtil.delCookie("JSESSIONID")
        cookieUtil.delCookie("userInfo")
        location.href = `${location.origin}/`
      },
      siderBarClick(navId) {
        this.$router.push({
          name: 'netMessage'
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.userInfo-container {
  display: flex;
  .userInfo1 {
    width: 180px;
    background-color: #F7F7F7;
    padding-top: 30px;
    .img-box {
      width: 68px;
      height: 68px;
      margin: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .sidebar-nav {
      width: 100%;
      & > div {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-left: 3px solid #f7f7f7;
      }
      .sidebar-nav-item-active {
        border-left: 3px solid #409EFF;
        color: #409EFF;
        background-color: #fff;
      }
      img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }
    }
  }
  .userInfo2 {
    flex: 1;
    min-height: 650px;
  }
  .userInfo3 {
    width: 220px;
    text-align: left;
    padding-left: 50px;
  }
}
</style>