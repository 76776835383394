<template>
  <div class="userCenter-container">
    <navigator :userInfo="userInfo"></navigator>
    <div style="width: 1200px;margin: auto;">
      <userInfo v-if="userInfo.id" :userInfo="userInfo"></userInfo>
    </div>
  </div>
</template>

<script>
  import cookieUtil from "@/util/cookieUtil"
  import navigator from './navigator'
  import userInfo from './userInfo'
  export default {
    name: "index",
    components: {
      navigator, userInfo
    },
    data() {
      return {
        userInfo: {
          id: '',
          userName: '',
          trueName: '',
          schoolName: ''
        }
      }
    },
    mounted() {
      this.$api.home.getUserInfo().then(res => {
        if (res.code == 200) {
          Object.assign(this.userInfo, res.result)
          cookieUtil.setCookie({
            key: 'userInfo',
            value: JSON.stringify(res.result)
          })
        }
      })
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
  .userCenter-container{
    background-color: #fff;
  }
</style>